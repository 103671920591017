





































































































import DataTable from '@/elements/DataTable/DataTable.vue'
import Loading from '@/elements/Loading.vue'
import { DataTableHeader } from 'vuetify/types'
import store from '@/store'
import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import communicationsClient from '@/clients/communicationsClient'
import { EmailSMSAccount } from '@/GeneratedTypes/Communication/EmailSMSAccount'
import { ScheduledCommunication } from '@/GeneratedTypes/Communication/ScheduledCommunication'
import Confirm from '@/components/ConfirmationModal.vue'
import router from '@/router/index'
import Filters from '@/views/Programs/Communications/vue/Filters/RecipientFilterList.vue'
import divisionsClient from '@/clients/divisionsClient'
import { ProgramTypes } from '@/lib/common/ProgramTypes'
import { LeagueTypeEnum } from '@/models/LeagueAbstraction/enums/LeagueTypeEnum'
export default defineComponent({
  name: 'Scheduled',
  components: {
    DataTable,
    Loading,
    Confirm,
    Filters,
  },
  props: {},
  setup() {
    const provider = computed((): EmailSMSAccount => store.getters.communications.currentItem)
    const loading = ref(false)
    const items = ref<ScheduledCommunication[]>([])
    const confirm = ref(false)
    const defaultCurrentItem = { isDeleting: false, isCanceling: false }
    const currentItem = ref<any>(defaultCurrentItem)
    const upwId = computed(() => store.getters.leagueAbstraction.currentItem.upwardLeagueID ?? '')
    const isRunningProgram = computed(
      () => store.getters.leagueAbstraction.currentItem.type === LeagueTypeEnum.RUNNING
    )
    const headers = ([
      { text: 'Type', value: 'type' },
      { text: 'From (email)', value: 'replyTo' },
      { text: 'Subject', value: 'shortSubject' },
      { text: 'Message', value: 'shortBody' },
      { text: 'Attachments', value: 'attachmentsCount', align: 'center' },
      ...(!isRunningProgram.value ? [{ text: '', value: 'data-table-expand' }] : []),
      { text: 'Date to Send', value: 'scheduledLocalTimeString' },
      { text: '', value: 'actions' },
    ] as unknown) as DataTableHeader

    const loadItems = async (showWait: boolean) => {
      try {
        loading.value = showWait
        const divisions = (await divisionsClient.retrieveAll(upwId.value)) ?? []
        const teams = await store.dispatch.teams.fetchTeams({ upwId: upwId.value })
        const id: number = provider.value.emailSmsAccountID
        const league: string = provider.value.upwardLeagueID!
        const comms = await communicationsClient.getScheduledCommunications(id, league)
        items.value = comms.map((c) => {
          return {
            ...c,
            recipientCriteria: {
              ...(c.recipientCriteria ?? {}),
              program: ProgramTypes[c.recipientCriteria?.typeProgramID ?? ''],
              teamNames:
                c.recipientCriteria?.teams
                  ?.map((t) => teams.find((at) => at.teamID === t)?.teamName)
                  .join(', ') ?? '',
              divisionNames:
                divisions.find((d) => d.divisionID === parseInt(c.recipientCriteria?.divisionID ?? '0'))
                  ?.divisionName ?? '',
              recipientGroupNames: c.recipientCriteria?.selectedGroups?.join(', ') ?? '',
            },
            attachmentsCount: c.type === 'EMAIL' ? `${c.attachmentNames?.length ?? 0}` : '',
            isDeleting: false,
            isCanceling: false,
          }
        })
      } finally {
        loading.value = false
      }
    }
    const actionConfirmed = async (confirmed: boolean) => {
      try {
        if (!confirmed) return
        await deleteItem()
        if (currentItem.value.isDeleting) await loadItems(false)
        else {
          const isShareable = currentItem.value.upwardType === 'GROUP'
          if (isShareable) router.push(`groupMessages/${currentItem.value.messageID}`)
          else router.push(`${currentItem.value.type.toLowerCase()}/${currentItem.value.messageID}`)
        }
      } finally {
        confirm.value = false
        currentItem.value.isDeleting = false
        currentItem.value.isCanceling = false
        currentItem.value = defaultCurrentItem
      }
    }
    const deleteItem = async () => {
      await communicationsClient.deleteScheduledCommunication(
        currentItem.value.upwardType,
        currentItem.value.messageID
      )
    }
    const handleDelete = (item: any) => {
      item.isDeleting = true
      currentItem.value = item
      confirm.value = true
    }
    const handleCancel = (item: any) => {
      item.isCanceling = true
      currentItem.value = item
      confirm.value = true
    }
    onMounted(async () => {
      await loadItems(true)
    })
    return {
      loading,
      headers,
      items,
      currentItem,
      confirm,
      actionConfirmed,
      handleDelete,
      handleCancel,
      isRunningProgram,
    }
  },
})
