var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[(_vm.loading)?_c('div',[_c('loading')],1):_c('div',[_vm._m(0),_c('div',{staticClass:"container-fluid mb-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-11 col-sm-11 mb-4"},[_c('div',[_c('div',{staticClass:"grid-panel"},[_c('DataTable',{staticClass:"elevation-1 data-table-zebra",attrs:{"headers":_vm.headers,"items":_vm.items,"showExpand":!_vm.isRunningProgram},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.upwardType === 'GROUP')?_c('span',{staticClass:"text-secondary message-type",attrs:{"title":"Shareable Link"}},[_vm._v(" shareable "+_vm._s(item.type.toLowerCase())+" ")]):(item.type === 'SMS')?_c('span',{staticClass:"text-secondary message-type",attrs:{"title":"SMS"}},[_vm._v(_vm._s(item.type.toLowerCase()))]):_c('span',{staticClass:"text-secondary message-type",attrs:{"title":"Email"}},[_vm._v(_vm._s(item.type.toLowerCase()))])]}},{key:"item.shortSubject",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"175px"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.shortBody",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"350px"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [[_c('div',[(item.isCanceling)?_c('loading',{attrs:{"is-small":""}}):_c('button',{staticClass:"btn btn-link",attrs:{"disabled":item.isDeleting,"title":"Edit"},on:{"click":function($event){return _vm.handleCancel(item)}}},[_c('i',{staticClass:"fas fa-pencil-alt",staticStyle:{"cursor":"pointer"}})]),_c('button',{staticClass:"btn btn-link",attrs:{"disabled":item.isCanceling,"title":"Delete"},on:{"click":function($event){return _vm.handleDelete(item)}}},[(item.isDeleting)?_c('loading',{attrs:{"is-small":""}}):_c('i',{staticClass:"fas fa-trash"})],1)],1)]]}},(!_vm.isRunningProgram)?{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"10px 15px","background-color":"#fff"},attrs:{"colspan":headers.length}},[_c('Filters',_vm._b({},'Filters',item.recipientCriteria,false))],1)]}}:null],null,true)})],1)])])])]),_c('confirm',{attrs:{"show":_vm.confirm,"title":((_vm.currentItem.isDeleting ? 'Delete' : 'Cancel') + " this communication"),"is-small":true},on:{"onResponse":_vm.actionConfirmed}},[(_vm.currentItem.isCanceling)?_c('div',[_vm._v(" This will cancel the current communication, allowing you to edit the existing content to resend immediately or reschedule for a future send. Do you want to continue? ")]):_c('div',[_vm._v(" This will cancel the current communication, you will have to recreate the message to send again. Do you want to continue? ")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-sm-6 ml-4"},[_c('h5',[_vm._v("Scheduled")])])])}]

export { render, staticRenderFns }